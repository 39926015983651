<template>
  <span class="form-error" v-if="error" v-cloak>
    <span class="pi pi-exclamation-circle align-middle"></span>
    <span class="ml-1 align-middle">{{ error }}</span>
  </span>
  <span class="alert-error" v-else-if="alert" v-cloak>
    <template v-if="Array.isArray(alert)">
      <span class="pi pi-exclamation-circle align-middle"></span>
      <span class="ml-1 align-middle">
        <p v-for="msg in alert">{{ msg }}</p>
      </span>
    </template>
    <template v-else>
      <span class="pi pi-exclamation-circle align-middle"></span>
      <span class="ml-1 align-middle">{{ alert }}</span>
    </template>
  </span>
</template>

<script>
export default {
  props: ['error', 'alert'],
}
</script>
