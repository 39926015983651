import { createApp } from 'vue'
import { string } from 'yup'
import axios from 'axios'

import { formFactory } from './validations'
import FormError from './components/FormError'
import Spinner from './components/Spinner'

const Login = {
  components: { FormError, Spinner },
  setup() {
    const fields = {
      email: string().required('Email address is required.').email('Must be a valid email.'),
      password: string().required('Password is required.'),
    }

    const apiRequest = async (values) => {
      return axios.post('/api/login', values).then((response) => {
        window.location = response.data.redirect
      })
    }

    const form = formFactory({ fields, apiRequest, validateOnValueUpdate: false })
    return { form }
  },
}

const apps = {
  Login,
}

window.renderApp = (name) => {
  const app = createApp(apps[name])
  app.mount('#app')
}
