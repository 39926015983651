import { ref, reactive } from 'vue'
import { useForm, useField } from 'vee-validate'
import { object, number as yupNumber, date as yupDate } from 'yup'

import { showToastError } from './utils'

const locale = {
  number: {
    integer: '${path} must be a whole number',
  },
}

function normalizeErrors(errors) {
  // Show first error only
  let formErrors = {}
  Object.keys(errors).forEach((error) => {
    formErrors[error] = errors[error][0]
  })
  return formErrors
}

function formFactory({ fields, apiRequest, initialValues = {}, validateOnValueUpdate = true } = {}) {
  const schema = object(fields)
  const { values, errors, isSubmitting, handleSubmit, setErrors, setFieldValue, setValues, resetForm } = useForm({
    validationSchema: schema,
    initialValues,
  })

  Object.keys(fields).forEach((field) => {
    useField(field, fields[field], { validateOnValueUpdate })
  })

  const nonFieldError = ref('')

  const onSubmit = handleSubmit((values, actions) => {
    return new Promise((resolve) => {
      apiRequest(values, actions)
        .catch(({ response }) => {
          if (response && response.data) {
            if (response.data.errors) {
              setErrors(normalizeErrors(response.data.errors))
            } else if (response.data.error) {
              nonFieldError.value = response.data.error
            } else {
              // nonFieldError.value = 'Something went wrong.'
              showToastError('Something went wrong')
            }
          }
        })
        .finally(() => {
          resolve()
        })
    })
  })

  const onReset = () => {
    resetForm()
    nonFieldError.value = ''
  }

  return reactive({ values, errors, nonFieldError, isSubmitting, onSubmit, onReset, setFieldValue, setValues })
}

function number() {
  return yupNumber().typeError('${path} must be a valid number')
}

function nullableNumber() {
  return number()
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
}

function date() {
  return yupDate().typeError('${path} must be a valid date')
}

export { locale, formFactory, number, nullableNumber, date }
