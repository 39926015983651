<template>
  <span v-if="loading" v-cloak><i class="pi pi-spin pi-spinner mr-1"></i> {{ loadingLabel }}..</span>
  <span v-else>{{ label }}</span>
</template>

<script>
export default {
  props: ['loading', 'label', 'loadingLabel'],
}
</script>
